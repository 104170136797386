import React from "react";
import { Col, Row } from "react-bootstrap";
import { DiReact, DiNodejs, DiJava,DiMysql } from "react-icons/di";
import {SiCsharp ,SiNextdotjs,SiDotnet,SiSpring, SiMicrosoftsqlserver } from "react-icons/si";
import genexus from "../../Assets/genexus.png";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      
      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Java</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <DiJava />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Spring Boot</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiSpring />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">C#</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiCsharp />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">.NET</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiDotnet />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Genexus</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <img src={genexus} className="img-fluid" alt="avatar" />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Nodejs</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <DiNodejs />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">React</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <DiReact />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Next.js</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiNextdotjs />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">MySql</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <DiMysql />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">SQL Server</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiMicrosoftsqlserver />
        </Col>
      </OverlayTrigger>
      
    </Row>
  );
}

export default Techstack;
