import React from "react";
import { Col, Row } from "react-bootstrap";
import fiservImg from "../../Assets/fiservv.png";
import prontoImg from "../../Assets/pronto.png";
import pjImg from "../../Assets/pj.png";
import garinoImg from "../../Assets/garino.png";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function WorkExperience() {
    return (
        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>

            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Fiserv</Tooltip>}>
                <Col xs={4} md={2} className="tech-icons">
                    <img src={fiservImg} className="img-fluid" alt="avatar" />
                </Col>
            </OverlayTrigger>

            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Poder Judicial del Uruguay</Tooltip>}>
                <Col xs={4} md={2} className="tech-icons">
                    <img src={pjImg} className="img-fluid" alt="avatar" />
                </Col>
            </OverlayTrigger>

            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Pronto+</Tooltip>}>
                <Col xs={4} md={2} className="tech-icons">
                    <img src={prontoImg} className="img-fluid" alt="avatar" />
                </Col>
            </OverlayTrigger>

            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Garino Hnos.</Tooltip>}>
                <Col xs={4} md={2} className="tech-icons">
                    <img src={garinoImg} className="img-fluid" alt="avatar" />
                </Col>
            </OverlayTrigger>

        </Row>
    );
}

export default WorkExperience;
