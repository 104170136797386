import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Education from "./Education";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import WorkExperience from "./WorkExperience";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";
import { useTranslation } from 'react-i18next';

function About() {

  const { t, i18n } = useTranslation();
  const language = i18n.language;

  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
               {t('know')} <strong className="purple">{t('imm')}</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>

        {language === 'es' ? 
          <h1 className="project-heading">
            Habilidades <strong className="purple">Técnicas </strong>
          </h1>
          :
          <h1 className="project-heading">
            Professional <strong className="purple">Skillset </strong>
          </h1>
        }
        <Techstack />
        
        {language === 'es' ? 
          <h1 className="project-heading">
            Mis<strong className="purple"> Herramientas</strong>
          </h1>
          :
          <h1 className="project-heading">
            <strong className="purple">Tools</strong> I use
          </h1>
        }
        <Toolstack />
        
        {language === 'es' ? 
          <h1 className="project-heading">
            Experiencia<strong className="purple"> Laboral</strong>
          </h1>
          :
          <h1 className="project-heading">
            Work<strong className="purple"> Experience</strong>
          </h1>
        }
        <WorkExperience />

        {language === 'es' ? 
          <h1 className="project-heading">
            Mis<strong className="purple"> Estudios</strong>
          </h1>
          :
          <h1 className="project-heading">
            My <strong className="purple">Education</strong>
          </h1>
        }
        <Education />
      </Container>
    </Container>
  );
}

export default About;
