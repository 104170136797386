import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import editor from "../../Assets/Projects/adoptapet.png";
import laserclean from "../../Assets/Projects/laserclean.png";
import adoptaPet from "../../Assets/Projects/adoptapet.png";
import { useTranslation } from 'react-i18next';

function Projects() {
  const { t } = useTranslation();
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          {t('recent')}<strong className="purple"> {t('works')} </strong>
        </h1>
        <p style={{ color: "white" }}>
          {t('few_projects')}
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={adoptaPet}
              title="AdoptaPet"
              description= {t('project_1')}
              demoLink="https://adoptapet.com.uy"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={laserclean}
              title="LaserClean"
              description={t('project_2')}
              demoLink="https://laserclean-nextjs.vercel.app/"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
