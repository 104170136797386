import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiWindows ,
  SiAmazonaws,
} from "react-icons/si";
import {
  DiGit
} from "react-icons/di";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Windows</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiWindows />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Visual Studio Code</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiVisualstudiocode />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Postman</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiPostman />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Slack</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiSlack />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Vercel</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiVercel />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">GitHub</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <DiGit />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Amazon Web Services</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiAmazonaws />
        </Col>
      </OverlayTrigger>
    </Row>
  );
}

export default Toolstack;
