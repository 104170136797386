import React from "react";
import Card from "react-bootstrap/Card";
import { useTranslation } from 'react-i18next';

function AboutCard() {
  const { t } = useTranslation();
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            {t('hi')}  <span className="purple">Alexis Rosano </span>
            {t('from')} <span className="purple"> Montevideo, Uruguay.</span>
            <br />
            {t('working')}
            <br />
            {t('graduated')}
            <br />
            <br />
            {t('coding')}
          </p>
          <ul>
            <li className="about-activity">
              ✈️ {t('travelling')}
            </li>
            <li className="about-activity">
            ⚽ {t('soccer')} 
            </li>
            <li className="about-activity">
              🩴 {t('beach')}
            </li>
            <li className="about-activity">
              💼 {t('more_travelling')}
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
