import React from "react";
import { Col, Row } from "react-bootstrap";
import elbioImg from "../../Assets/elbio.png";
import ortImg from "../../Assets/ort.png";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function Education() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      
      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Universidad ORT</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <img src={ortImg} className="img-fluid" alt="avatar" />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Elbio Fernandez</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <img src={elbioImg} className="img-fluid" alt="avatar" />
        </Col>
      </OverlayTrigger>
      
    </Row>
  );
}

export default Education;
